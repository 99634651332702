import React from 'react';

const MagicitEditor = () => {
  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <iframe
        src="https://ai4editing-magicquill.hf.space"
        title="Magicit Editor"
        style={{ border: 'none', width: '100%', height: '100%' }}
      />
    </div>
  );
};

export default MagicitEditor;
