import React from 'react';
import { motion } from 'framer-motion';

// Import feature GIFs
const featureGifs = [
  { id: 'beautiful-hair', name: 'Beautiful Hair Generation', src: '/features-gifs/beautiful hair.gif' },
  { id: 'handsome-hair', name: 'Handsome Hair Style', src: '/features-gifs/handsome hair.gif' },
  { id: 'skeleton-cowboy', name: 'Skeleton Cowboy', src: '/features-gifs/skeleton cowboy.gif' },
  { id: 'manga-sword', name: 'Manga Style Sword', src: '/features-gifs/manga sword.gif' },
  { id: 'robot-antenna', name: 'Robot Antenna', src: '/features-gifs/robot antenna.gif' },
  { id: 'mona-lisa-cat', name: 'Mona Lisa Cat', src: '/features-gifs/mona lisa cat.gif' },
  { id: 'dolphin', name: 'Dolphin Generation', src: '/features-gifs/dolphin.gif' },
  { id: 'cake-flowers', name: 'Cake with Flowers', src: '/features-gifs/cake flowers.gif' },
  { id: 'deer', name: 'Deer Generation', src: '/features-gifs/deer.gif' },
  { id: 'mask', name: 'Mask Generation', src: '/features-gifs/mask.gif' },
  { id: 'feather', name: 'Feather Effect', src: '/features-gifs/feather.gif' },
  { id: 'hat', name: 'Hat Generation', src: '/features-gifs/hat.gif' },
];

const Features = () => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen pt-16 bg-background">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div>
            <h1 className="text-3xl font-semibold text-primary">Features</h1>
            <p className="mt-2 text-muted-foreground">
              Explore Magicit's amazing capabilities
            </p>
          </div>
        </div>

        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="grid gap-6 mt-8 sm:grid-cols-2 lg:grid-cols-3"
        >
          {featureGifs.map((gif) => (
            <motion.div
              key={gif.id}
              variants={item}
              className="bg-card rounded-lg shadow-sm overflow-hidden"
            >
              <div className="aspect-video">
                <img
                  src={gif.src}
                  alt={gif.name}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-medium text-primary">{gif.name}</h3>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Features;
