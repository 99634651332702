import React from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PricingPlans = () => {
  const navigate = useNavigate();

  const plans = [
    {
      name: 'Free Trial',
      price: 0,
      duration: '3 days',
      features: [
        'Basic image editing',
        'Up to 5 images',
        'Standard support',
        'No commercial use'
      ]
    },
    {
      name: 'Basic Plan',
      price: 7,
      duration: 'per month',
      features: [
        'Advanced image editing',
        'Up to 50 images',
        'Priority support',
        'No commercial use'
      ]
    },
    {
      name: 'Pro Plan',
      price: 15,
      duration: 'per month',
      features: [
        'Premium image editing',
        'Unlimited images',
        '24/7 support',
        'Commercial use allowed'
      ]
    }
  ];

  const handleSubscribe = async (planName) => {
    if (planName === 'Free Trial') {
      navigate('/register');
      return;
    }

    try {
      const response = await fetch('/api/subscription/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          plan: planName.toLowerCase().replace(' ', '_')
        }),
      });

      const { sessionId } = await response.json();
      const stripe = await stripePromise;
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="py-12 bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Choose Your Plan
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Select the perfect plan for your creative needs
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0">
          {plans.map((plan) => (
            <div key={plan.name} className="bg-white rounded-lg shadow-lg divide-y divide-gray-200">
              <div className="p-6">
                <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">${plan.price}</span>
                  <span className="text-base font-medium text-gray-500">/{plan.duration}</span>
                </p>
                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <svg className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <p className="ml-3 text-base text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
                <button
                  onClick={() => handleSubscribe(plan.name)}
                  className={`mt-8 block w-full text-white font-semibold px-6 py-3 rounded-md text-center ${
                    plan.name === 'Free Trial'
                      ? 'bg-[#7c64c4] hover:bg-[#6b58b0]'
                      : plan.name === 'Basic Plan'
                      ? 'bg-[#448cb4] hover:bg-[#3e7ca0]'
                      : 'bg-[#0cbc64] hover:bg-[#0aa857]'
                  }`}
                >
                  {plan.name === 'Free Trial' ? 'Start Free Trial' : 'Subscribe Now'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingPlans;
