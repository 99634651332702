import React from 'react';
import { motion } from 'framer-motion';
import { Download, Share2, Trash2, Edit2 } from 'lucide-react';

const Gallery = () => {
  const [images] = React.useState([
    {
      id: 1,
      name: 'Mountain Landscape',
      preview: 'https://images.unsplash.com/photo-1506905925346-21bda4d32df4',
      createdAt: '2023-09-10',
      edits: ['Style Transfer', 'Color Enhancement'],
    },
    // Add more mock images here
  ]);

  const [selectedImage, setSelectedImage] = React.useState(null);
  const [view, setView] = React.useState('grid'); // 'grid' or 'list'

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen pt-16 bg-background">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <div>
            <h1 className="text-3xl font-semibold text-primary">Gallery</h1>
            <p className="mt-2 text-muted-foreground">
              View and manage your edited images
            </p>
          </div>
          <div className="mt-4 sm:mt-0">
            <div className="flex space-x-3">
              <button
                onClick={() => setView('grid')}
                className={`px-4 py-2 text-sm font-medium rounded-md ${
                  view === 'grid'
                    ? 'bg-primary text-primary-foreground'
                    : 'text-muted-foreground hover:text-primary hover:bg-accent'
                }`}
              >
                Grid
              </button>
              <button
                onClick={() => setView('list')}
                className={`px-4 py-2 text-sm font-medium rounded-md ${
                  view === 'list'
                    ? 'bg-primary text-primary-foreground'
                    : 'text-muted-foreground hover:text-primary hover:bg-accent'
                }`}
              >
                List
              </button>
            </div>
          </div>
        </div>

        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className={`mt-8 ${
            view === 'grid'
              ? 'grid gap-6 sm:grid-cols-2 lg:grid-cols-3'
              : 'space-y-4'
          }`}
        >
          {images.map((image) => (
            <motion.div
              key={image.id}
              variants={item}
              className={`bg-card rounded-lg shadow-sm overflow-hidden ${
                view === 'list' ? 'flex' : ''
              }`}
              onClick={() => setSelectedImage(image)}
            >
              <div
                className={`relative ${
                  view === 'list' ? 'w-48' : 'aspect-video'
                }`}
              >
                <img
                  src={image.preview}
                  alt={image.name}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="p-4">
                <h3 className="font-medium text-primary">{image.name}</h3>
                <p className="mt-1 text-sm text-muted-foreground">
                  Created on {image.createdAt}
                </p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {image.edits.map((edit) => (
                    <span
                      key={edit}
                      className="px-2 py-1 text-xs font-medium bg-accent text-accent-foreground rounded-full"
                    >
                      {edit}
                    </span>
                  ))}
                </div>
                <div className="flex gap-2 mt-4">
                  <button className="p-2 text-muted-foreground hover:text-primary hover:bg-accent rounded-md">
                    <Edit2 className="w-4 h-4" />
                  </button>
                  <button className="p-2 text-muted-foreground hover:text-primary hover:bg-accent rounded-md">
                    <Download className="w-4 h-4" />
                  </button>
                  <button className="p-2 text-muted-foreground hover:text-primary hover:bg-accent rounded-md">
                    <Share2 className="w-4 h-4" />
                  </button>
                  <button className="p-2 text-muted-foreground hover:text-destructive hover:bg-destructive/10 rounded-md">
                    <Trash2 className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>

        {selectedImage && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-background/80 backdrop-blur-sm">
            <div className="w-full max-w-3xl p-6 bg-card rounded-lg shadow-lg">
              <div className="relative aspect-video">
                <img
                  src={selectedImage.preview}
                  alt={selectedImage.name}
                  className="object-contain w-full h-full"
                />
              </div>
              <div className="mt-4">
                <h2 className="text-xl font-semibold text-primary">
                  {selectedImage.name}
                </h2>
                <p className="mt-1 text-muted-foreground">
                  Created on {selectedImage.createdAt}
                </p>
                <div className="flex gap-4 mt-4">
                  <button className="px-4 py-2 text-sm font-medium bg-primary text-primary-foreground rounded-md hover:bg-primary/90">
                    Download
                  </button>
                  <button
                    onClick={() => setSelectedImage(null)}
                    className="px-4 py-2 text-sm font-medium bg-secondary text-secondary-foreground rounded-md hover:bg-secondary/90"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
