import React from 'react';
import { motion } from 'framer-motion';
import { Image as ImageIcon, Zap, Clock } from 'lucide-react';
import { useDropzone } from 'react-dropzone';

const Dashboard = () => {
  const [recentImages, setRecentImages] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);

  const generateImage = async (params) => {
    try {
      const response = await fetch('/generate-image', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      });
      const result = await response.json();
      console.log(result);
    } catch (error) {
      console.error('Error generating image:', error);
    }
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    setIsUploading(true);
    // Handle file upload logic here
    setTimeout(() => {
      setIsUploading(false);
      // Mock adding new images
      setRecentImages(prev => [...acceptedFiles.map(file => ({
        id: Math.random(),
        name: file.name,
        preview: URL.createObjectURL(file),
        status: 'processing'
      })), ...prev]);
    }, 2000);
  }, []);

  const { getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg', '.gif']
    }
  });

  const stats = [
    { name: 'Total Edits', value: '10', icon: Zap },
    { name: 'Images Processed', value: '33', icon: ImageIcon },
    { name: 'Usage Time', value: '3 hrs', icon: Clock },
  ];

  const handleGenerateImage = () => {
    const params = {
      x: "Hello!!",
      ckpt_name: "SD1.5/realisticVisionV60B1_v51VAE.safetensors",
      // Add other parameters as needed
    };
    generateImage(params);
  };

  return (
    <div className="min-h-screen pt-16 bg-background">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-semibold text-primary">Dashboard</h1>
            <p className="mt-2 text-muted-foreground">
              Transform your images with AI-powered editing tools
            </p>
          </div>
        </div>

        <div className="grid gap-6 mt-8 md:grid-cols-3">
          {stats.map((stat) => (
            <motion.div
              key={stat.name}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="p-6 bg-card rounded-lg shadow-sm"
            >
              <div className="flex items-center">
                <div className="p-2 bg-primary/10 rounded-lg">
                  <stat.icon className="w-6 h-6 text-primary" />
                </div>
                <div className="ml-4">
                  <p className="text-sm font-medium text-muted-foreground">
                    {stat.name}
                  </p>
                  <p className="text-2xl font-semibold text-primary">
                    {stat.value}
                  </p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="mt-8">
          {recentImages.length > 0 && (
            <div className="mt-8">
              <h2 className="text-xl font-semibold text-primary">Recent Images</h2>
              <div className="grid gap-6 mt-4 sm:grid-cols-2 lg:grid-cols-3">
                {recentImages.map((image) => (
                  <motion.div
                    key={image.id}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="overflow-hidden bg-card rounded-lg shadow-sm"
                  >
                    <div className="relative aspect-video">
                      <img
                        src={image.preview}
                        alt={image.name}
                        className="object-cover w-full h-full"
                      />
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          )}
        </div>

        {isUploading && (
          <div className="p-4 mt-4 bg-card rounded-lg">
            <div className="flex items-center">
              <div className="w-full bg-muted rounded-full h-2.5">
                <div className="bg-primary h-2.5 rounded-full w-1/2"></div>
              </div>
              <span className="ml-4 text-sm text-muted-foreground">50%</span>
            </div>
          </div>
        )}
        <div {...getInputProps()} className="p-4 mt-4 bg-card rounded-lg border-2 border-dashed border-primary">
          <p className="text-sm text-muted-foreground">Drag and drop images here or click to upload</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
