import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'next-themes';
import './styles/globals.css'; // Adjust the path if necessary
import App from './App';

ReactDOM.render(
  <ThemeProvider attribute="class">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);
