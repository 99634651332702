import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import Register from './components/Register';
import PricingPlans from './components/PricingPlans';
import Gallery from './components/Gallery';
import Features from './components/Features';
import Settings from './components/Settings';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Support from './components/Support';
import MagicitEditor from './components/MagicitEditor';
import { useTheme } from 'next-themes';
// import { useConversation } from '@11labs/react';

function App() {
  const { theme } = useTheme();
  /* Temporarily disabled ElevenLabs integration
  const conversation = useConversation({
    onConnect: () => console.log('Connected to AI agent'),
    onDisconnect: () => console.log('Disconnected from AI agent'),
    onError: (error) => console.error('AI agent error:', error),
  });

  useEffect(() => {
    const initConversation = async () => {
      try {
        await conversation.startSession({
          agentId: process.env.REACT_APP_ELEVENLABS_AGENT_ID,
        });
      } catch (error) {
        console.error('Failed to start AI conversation:', error);
      }
    };

    initConversation();

    return () => {
      conversation.endSession();
    };
  }, [conversation]);
  */

  return (
    <Router>
      <div className={`min-h-screen ${theme === 'dark' ? 'bg-black text-white' : 'bg-gray-100'}`}>
        <Navigation />
        <main className="container mx-auto px-4">
          <Routes>
            <Route path="/" element={
              <section className={`hero ${theme === 'dark' ? 'bg-black text-white' : 'bg-white text-black'}`}>
                <div className="text-center py-20">
                  <h1 className="text-4xl font-bold">Welcome to MagicIt</h1>
                  <div className="mt-6 space-y-4">
                    <p className="text-2xl font-light animate-pulse">✨ Transform your images instantly with AI-powered magic! ✨</p>
                    <div className="max-w-2xl mx-auto backdrop-blur-md bg-white/10 rounded-xl p-6 shadow-lg">
                      <p className="text-lg opacity-90">
                      Add or remove objects, change backgrounds, enhance colors, and more.
                        <br />
                        Join thousands of creators who are already making magic happen!
                      </p>
                    </div>
                    
                    {/* Feature highlights */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-12 max-w-4xl mx-auto">
                      <div className="transform hover:scale-105 transition-transform duration-300">
                        <div className="backdrop-blur-md bg-white/10 rounded-xl p-6 shadow-lg h-full">
                          <div className="text-3xl mb-4">🎨</div>
                          <h3 className="text-xl font-semibold mb-2">AI-Powered Editing</h3>
                          <p>Transform your images with cutting-edge AI technology</p>
                        </div>
                      </div>
                      <div className="transform hover:scale-105 transition-transform duration-300">
                        <div className="backdrop-blur-md bg-white/10 rounded-xl p-6 shadow-lg h-full">
                          <div className="text-3xl mb-4">⚡</div>
                          <h3 className="text-xl font-semibold mb-2">Instant Results</h3>
                          <p>Get professional-quality edits in seconds</p>
                        </div>
                      </div>
                      <div className="transform hover:scale-105 transition-transform duration-300">
                        <div className="backdrop-blur-md bg-white/10 rounded-xl p-6 shadow-lg h-full">
                          <div className="text-3xl mb-4">🔮</div>
                          <h3 className="text-xl font-semibold mb-2">Creative Freedom</h3>
                          <p>Unleash your creativity with unlimited possibilities</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Video section with glass effect */}
                <div className="relative z-10 max-w-5xl mx-auto mb-16">
                  <div className="backdrop-blur-md bg-white/10 rounded-xl p-4 shadow-lg">
                    <iframe 
                      className="w-full aspect-video rounded-lg shadow-xl"
                      src="https://www.youtube.com/embed/SDlZldsrqW0?si=pFh5ZPQXUpQCZJA1" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                      allowfullscreen
                    />
                  </div>
                </div>

                <PricingPlans />
              </section>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/features" element={<Features />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/editor" element={<MagicitEditor />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/support" element={<Support />} />
          </Routes>
        </main>
        <Footer />
        {/* Temporarily disabled ElevenLabs widget
        <elevenlabs-convai 
          agent-id={process.env.REACT_APP_ELEVENLABS_AGENT_ID}
          className="fixed bottom-4 right-4 z-50"
        />
        */}
      </div>
    </Router>
  );
}

export default App;
