import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'next-themes';
import {
  Sun,
  Moon,
  Menu,
  User,
  Settings,
  LogOut,
  Image as ImageIcon,
  Home,
} from 'lucide-react';

const Navigation = () => {
  const { theme, setTheme } = useTheme();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const navigation = [
    { name: 'Features', href: '/features', icon: ImageIcon },
    { name: 'Gallery', href: '/gallery', icon: ImageIcon },
    { name: 'Dashboard', href: '/dashboard', icon: Home },
    { name: 'Settings', href: '/settings', icon: Settings },
  ];

  return (
    <nav className="fixed top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex items-center flex-shrink-0">
              <Link to="/" className="flex items-center">
                <span className="text-2xl font-bold text-primary">MagicIt</span>
              </Link>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={`inline-flex items-center px-1 pt-1 text-sm font-medium ${
                    location.pathname === item.href
                      ? 'text-primary border-b-2 border-primary'
                      : 'text-muted-foreground hover:text-primary'
                  }`}
                >
                  <item.icon className="w-4 h-4 mr-2" />
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          <div className="flex items-center">
            <button
              onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
              className="p-2 text-muted-foreground rounded-md hover:text-primary hover:bg-accent"
            >
              {theme === 'dark' ? (
                <Sun className="w-5 h-5" />
              ) : (
                <Moon className="w-5 h-5" />
              )}
            </button>

            <Link
              to="/editor"
              className="ml-4 px-6 py-2 bg-[#0aa857] text-white rounded-lg hover:bg-[#099048] transition-all duration-300 hover:shadow-lg hover:-translate-y-0.5"
            >
              Try Now
            </Link>

            <div className="hidden sm:ml-6 sm:flex sm:items-center">
              <div className="relative ml-3">
                <div>
                  <button
                    className="flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  >
                    <User className={`w-8 h-8 p-1 rounded-full ${theme === 'dark' ? 'text-black bg-white' : 'text-white bg-primary'}`} />
                  </button>
                </div>
                {isMenuOpen && (
                  <div className="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-background rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <Link
                      to="/settings"
                      className="flex items-center px-4 py-2 text-sm text-muted-foreground hover:bg-accent"
                    >
                      <Settings className="w-4 h-4 mr-2" />
                      Settings
                    </Link>
                    <button
                      className="flex items-center w-full px-4 py-2 text-sm text-muted-foreground hover:bg-accent"
                      onClick={() => {/* Add logout logic */}}
                    >
                      <LogOut className="w-4 h-4 mr-2" />
                      Sign out
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 text-muted-foreground rounded-md hover:text-primary hover:bg-accent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <Menu className="w-6 h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  location.pathname === item.href
                    ? 'text-primary bg-accent'
                    : 'text-muted-foreground hover:text-primary hover:bg-accent'
                }`}
              >
                <div className="flex items-center">
                  <item.icon className="w-4 h-4 mr-2" />
                  {item.name}
                </div>
              </Link>
            ))}
            <Link
              to="/editor"
              className="block mx-3 px-4 py-2 bg-[#0aa857] text-white text-center rounded-lg hover:bg-[#099048]"
            >
              Try Now
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
