import React, { useState } from 'react';

const Support = () => {
  const [ticket, setTicket] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle ticket submission logic here
    alert(`Ticket submitted: ${ticket}`);
    setTicket('');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-center mb-4">Support</h2>
      <form onSubmit={handleSubmit} className="max-w-md mx-auto">
        <textarea
          className="w-full h-32 p-2 border border-gray-300 rounded mb-4"
          placeholder="Describe your issue..."
          value={ticket}
          onChange={(e) => setTicket(e.target.value)}
          required
        />
        <button
          type="submit"
          className="w-full bg-primary text-white py-2 rounded hover:bg-primary-dark"
        >
          Submit Ticket
        </button>
      </form>
    </div>
  );
};

export default Support;
