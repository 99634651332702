import React from 'react';
import { motion } from 'framer-motion';
import { ChevronLeft } from 'lucide-react';

const Settings = () => {
  const [activeTab, setActiveTab] = React.useState('account');
  const [currentPlan, setCurrentPlan] = React.useState('free');

  const tabs = [
    { id: 'account', name: 'Account', icon: ChevronLeft },
    { id: 'subscription', name: 'Subscription', icon: ChevronLeft },
    { id: 'notifications', name: 'Notifications', icon: ChevronLeft },
    { id: 'security', name: 'Security', icon: ChevronLeft },
    { id: 'billing', name: 'Billing History', icon: ChevronLeft },
  ];

  const plans = [
    {
      id: 'free',
      name: 'Free Trial',
      price: 0,
      duration: '3 days',
      features: [
        'Basic image editing',
        'Up to 5 images',
        'Standard support',
        'No commercial use',
      ],
    },
    {
      id: 'basic',
      name: 'Basic Plan',
      price: 7,
      duration: 'per month',
      features: [
        'Advanced image editing',
        'Up to 50 images',
        'Priority support',
        'No commercial use',
      ],
    },
    {
      id: 'pro',
      name: 'Pro Plan',
      price: 15,
      duration: 'per month',
      features: [
        'Premium image editing',
        'Unlimited images',
        '24/7 support',
        'Commercial use allowed',
      ],
    },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'subscription':
        return (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-primary">
              Current Subscription
            </h3>
            <div className="grid gap-6 mt-4 md:grid-cols-3">
              {plans.map((plan) => (
                <motion.div
                  key={plan.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`relative p-6 bg-card rounded-lg ${
                    currentPlan === plan.id
                      ? 'ring-2 ring-primary'
                      : 'hover:shadow-md'
                  }`}
                >
                  {currentPlan === plan.id && (
                    <div className="absolute top-0 right-0 p-1 translate-x-1/4 -translate-y-1/4 bg-primary rounded-full">
                      <ChevronLeft className="w-4 h-4 text-primary-foreground" />
                    </div>
                  )}
                  <h4 className="text-lg font-medium text-primary">
                    {plan.name}
                  </h4>
                  <p className="mt-2">
                    <span className="text-3xl font-bold text-primary">
                      ${plan.price}
                    </span>
                    <span className="text-muted-foreground">/{plan.duration}</span>
                  </p>
                  <ul className="mt-6 space-y-4">
                    {plan.features.map((feature) => (
                      <li key={feature} className="flex items-center">
                        <ChevronLeft className="w-4 h-4 mr-2 text-primary" />
                        <span className="text-sm text-muted-foreground">
                          {feature}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <button
                    onClick={() => setCurrentPlan(plan.id)}
                    className={`w-full px-4 py-2 mt-6 text-sm font-medium rounded-md ${
                      currentPlan === plan.id
                        ? 'bg-primary text-primary-foreground'
                        : 'bg-secondary text-secondary-foreground hover:bg-secondary/90'
                    }`}
                  >
                    {currentPlan === plan.id ? 'Current Plan' : 'Switch Plan'}
                  </button>
                </motion.div>
              ))}
            </div>
          </div>
        );
      case 'billing':
        return (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-primary">Billing History</h3>
            <div className="mt-4 overflow-hidden bg-card rounded-lg">
              <table className="min-w-full divide-y divide-border">
                <thead>
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase">
                      Date
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase">
                      Amount
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-muted-foreground uppercase">
                      Invoice
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-border">
                  <tr>
                    <td className="px-6 py-4 text-sm text-primary">
                      Sep 1, 2023
                    </td>
                    <td className="px-6 py-4 text-sm text-primary">$15.00</td>
                    <td className="px-6 py-4">
                      <span className="px-2 py-1 text-xs font-medium bg-green-500/10 text-green-500 rounded-full">
                        Paid
                      </span>
                    </td>
                    <td className="px-6 py-4 text-sm text-primary">
                      <button className="text-primary hover:underline">
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        );
      default:
        return (
          <div className="mt-6">
            <h3 className="text-lg font-medium text-primary">Account Settings</h3>
            <div className="mt-4 space-y-4">
              <div className="p-4 bg-card rounded-lg">
                <label className="block text-sm font-medium text-primary">
                  Email
                </label>
                <input
                  type="email"
                  className="w-full px-3 py-2 mt-1 bg-background border rounded-md focus:ring-primary focus:border-primary"
                  value="user@example.com"
                  readOnly
                />
              </div>
              <div className="p-4 bg-card rounded-lg">
                <label className="block text-sm font-medium text-primary">
                  Password
                </label>
                <button className="px-4 py-2 mt-2 text-sm font-medium bg-secondary text-secondary-foreground rounded-md hover:bg-secondary/90">
                  Change Password
                </button>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen pt-16 bg-background">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h1 className="text-3xl font-semibold text-primary">Settings</h1>
        <div className="mt-8">
          <div className="sm:hidden">
            <select
              className="w-full rounded-md border-border bg-background"
              value={activeTab}
              onChange={(e) => setActiveTab(e.target.value)}
            >
              {tabs.map((tab) => (
                <option key={tab.id} value={tab.id}>
                  {tab.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`flex items-center px-3 py-2 text-sm font-medium rounded-md ${
                    activeTab === tab.id
                      ? 'bg-primary text-primary-foreground'
                      : 'text-muted-foreground hover:text-primary hover:bg-accent'
                  }`}
                >
                  <ChevronLeft className="w-4 h-4 mr-2" />
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default Settings;
